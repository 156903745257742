import './style.scss';
import { Timer } from '../../../js/components/Timer/Timer';
import {pushDSPCounter} from '../../../js/libs/pixel';
import { saleEndDate } from '../../../js/components/SaleEndDate/SaleEndDate'
import { showOrderModal } from '../../popups/v1/OrderModal/scripts'

export default !function () {
  const block = document.querySelector('.start-screen-v1');

  if (!block) {
    return;
  }

  const blockName = block.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия'
  const button = block.querySelector('.start-screen-v1__button');

  if (button) {
    const siteArea = button.dataset.pixel;

    button.addEventListener('click', (evt) => {
      evt.preventDefault();

      const buttonType = button.dataset.actionButton || (button.dataset.redirectLink && 'redirect')

      if (buttonType) {
        window.$analytics?.push('all-block-consult-button-click', {
          blockName,
          buttonType,
          buttonText: button.textContent
        })
      }

      if (button.dataset.actionButton === 'order-modal') {
        const blockName = button.closest('[data-block-name]')?.dataset.blockName ?? 'Блок без названия'
        showOrderModal({ blockName })
      }

      siteArea && pushDSPCounter(siteArea);
    });

    if (window.innerWidth < 1024) {
      const blocksWithFooter = document.querySelectorAll('.footer');
      const blocksWithButton = document.querySelectorAll('.block:not(.start-screen-v1) .ui-button');
      const blocks = [...blocksWithButton, ...blocksWithFooter];
      const buttons = document.querySelector('.start-screen-v1__buttons');

      let visibleCount = 1;

      const changeVisibleButton = (entries) => {
        entries.forEach(entry => {
          entry.isIntersecting ? ++visibleCount : --visibleCount;
          visibleCount = visibleCount > 0 ? visibleCount : 0;

          visibleCount
            ? buttons.classList.add('start-screen-v1__buttons--hidden')
            : buttons.classList.remove('start-screen-v1__buttons--hidden');
        })
      };

      const observer = new IntersectionObserver(changeVisibleButton, { rootMargin: '150px' });

      blocks.forEach(block => {
        observer.observe(block);
      })
    }
  }

  // init timer
  const timerWrapper = block.querySelector('.start-screen-v1__timer-block')

  if (timerWrapper) {
    const tick = ({ days, daysWord, hours, minutes, seconds }) => {
      timerWrapper.querySelector('[data-type=timer]')
        .innerHTML = `${days ? `<b class="f f--m">${days}</b>&nbsp;${daysWord}&nbsp;` : ''}<b class="f f--m">${hours}:${minutes}:${seconds}</b>`
    }

    saleEndDate
      .addObserver(time => new Timer({ time, timerWrapper, tickCallback: tick }))
  }

  // Sale
  const saleBlock = document.querySelector('.start-screen-v1--sale');

  if (saleBlock && timerWrapper) {
    const desktop = window.matchMedia('(min-width: 1024px)');

    const positionSale = () => {
      const tooltipHeight = timerWrapper.getBoundingClientRect().height;
      const margin = 4;
      const blockPadding = tooltipHeight + margin;

      saleBlock.style.paddingTop = `${blockPadding}px`;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => { // https://stackoverflow.com/a/58701523
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        positionSale()
      });
    });

    const mediaChangeListenerDesktop = (evt) => {
      if (evt.matches) {
        saleBlock.removeAttribute('style');
        resizeObserver.disconnect();
      } else {
        positionSale();
        resizeObserver.observe(timerWrapper);
      }
    }

    mediaChangeListenerDesktop(desktop);
    desktop.addEventListener('change', mediaChangeListenerDesktop);
  }
}();
